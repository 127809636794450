import type { FC, ReactNode } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N400 } from '@atlaskit/theme/colors';

import { useKeyboardShortcutsState } from './useKeyboardShortcutsState';
import { getShortcutString } from './get-shortcut-string';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyboardShortcutContainer = styled.div({
	display: 'flex',
	paddingBottom: token('space.025', '2px'),
	paddingTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyboardShortcut = styled.div<{ hasContentBefore?: boolean }>({
	backgroundColor: token('color.background.accent.gray.subtle', N400),
	borderRadius: '2px',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: (props) =>
		props.hasContentBefore ? token('space.050', '4px') : token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: (props) =>
		props.hasContentBefore ? token('space.0', '0px') : token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
	padding: `1px ${token('space.100', '8px')}`,
});

export type ShortcutVisualizerProps = {
	shortcut: string;
	contentBefore?: ReactNode;
	isEditorShortcut?: boolean;
};

export const ShortcutVisualizer: FC<ShortcutVisualizerProps> = ({
	shortcut,
	contentBefore,
	isEditorShortcut,
}) => {
	const [areKeyboardShortcutsEnabled] = useKeyboardShortcutsState();
	const shortcutString = getShortcutString(shortcut);

	return (
		<KeyboardShortcutContainer>
			{contentBefore}
			{(areKeyboardShortcutsEnabled || isEditorShortcut) &&
				shortcutString.split(' ').map((shortcutSegment, index) => (
					<KeyboardShortcut key={`${shortcutSegment}-${index}`} hasContentBefore={!!contentBefore}>
						{shortcutSegment}
					</KeyboardShortcut>
				))}
		</KeyboardShortcutContainer>
	);
};
